<template>
  <div class="EduHome">
    <AskForHeader />
    <Banner />
    <main>
      <div class="page-container1">
        <div class="HomeMian">
          <div class="homeDl">
            <dl>
              <dd class="on">
                <div>基础篇</div>
              </dd>
              <dd>
                <div>进阶篇</div>
              </dd>
              <dd>
                <div>金刚篇</div>
              </dd>
            </dl>
          </div>
          <div class="homeTitle">
            <span>特色课程</span>
          </div>
          <div class="homeItem">
            <dl>
              <dd>
                <div class="itemFont">
                  <div class="itemImg">
                    <img src="~@/assets/index/img.png" />
                  </div>
                  <div class="itemInfoFont">
                    <div class="title">股份支付</div>
                    <div class="content">
                      股票期权作为企业管理中的一种激励手段，最早起源于美国，在上世纪70~80年代走向成熟，被西方大多数企业采用
                    </div>
                    <div class="item">
                      <p>10-17周，8-15小时/周</p>
                      <p>1月31日 前申请</p>
                      <p>250￥</p>
                      <span>证书<i></i></span>
                    </div>
                  </div>
                </div>
              </dd>
              <dd>
                <div class="itemFont">
                  <div class="itemImg">
                    <img src="~@/assets/index/img.png" />
                  </div>
                  <div class="itemInfoFont">
                    <div class="title">股份支付</div>
                    <div class="content">
                      股票期权作为企业管理中的一种激励手段，最早起源于美国，在上世纪70~80年代走向成熟，被西方大多数企业采用
                    </div>
                    <div class="item">
                      <p>10-17周，8-15小时/周</p>
                      <p>1月31日 前申请</p>
                      <p>250￥</p>
                      <span>证书<i></i></span>
                    </div>
                  </div>
                </div>
              </dd>
              <dd>
                <div class="itemFont">
                  <div class="itemImg">
                    <img src="~@/assets/index/img.png" />
                  </div>
                  <div class="itemInfoFont">
                    <div class="title">股份支付</div>
                    <div class="content">
                      股票期权作为企业管理中的一种激励手段，最早起源于美国，在上世纪70~80年代走向成熟，被西方大多数企业采用
                    </div>
                    <div class="item">
                      <p>10-17周，8-15小时/周</p>
                      <p>1月31日 前申请</p>
                      <p>250￥</p>
                      <span>证书<i></i></span>
                    </div>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
          <div class="homeItem-more">探索所有课程 ></div>
        </div>
      </div>
      <div class="page-container1 homeBg">
        <div class="HomeMian">
          <div class="homeTitle">
            <span>了解你的数字化互动学习平台</span>
          </div>
          <div class="itemcentent">
            <div class="itemcententImg">
              <img src="~@/assets/index/pexels-markus-winkler-6815936.png" />
            </div>
            <div class="itemcententFont">
              <div class="itemcententTitle">公司股价</div>
              <div class="itemcententInfo">股份支付的确认和计量</div>
              <div class="itemcententC">
                规定如果管理层成员在其后3年中都在公司中任职服务，并且公司股价每年均提高10%以上
              </div>
              <div class="homeItem-more1">探索所有课程 ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-container1 homeBg1">
        <div class="HomeMian">
          <div class="feature">
            <div class="homeTitle mt0">
              <span>特色学习</span>
            </div>
            <div class="font">
              规定如果管理层成员在其后3年中都在公司中任职服务，并且公司股价每年均提高10%以上%以上
            </div>
            <div class="featureDl">
              <dl>
                <dd>
                  <div class="featureDl-icon">
                    <img src="~@/assets/index/b-icon.png" />
                  </div>
                  <div class="featureD-title">在线课程优势1</div>
                  <div class="featureD-font">
                    规定如果管理层成员在其后3年中都在公司中任职服务，并且公司股价每年均提高10%以上
                  </div>
                </dd>
                <dd>
                  <div class="featureDl-icon">
                    <img src="~@/assets/index/b-icon.png" />
                  </div>
                  <div class="featureD-title">在线课程优势2</div>
                  <div class="featureD-font">
                    规定如果管理层成员在其后3年中都在公司中任职服务，并且公司股价每年均提高10%以上
                  </div>
                </dd>
                <dd>
                  <div class="featureDl-icon">
                    <img src="~@/assets/index/b-icon.png" />
                  </div>
                  <div class="featureD-title">在线课程优势3</div>
                  <div class="featureD-font">
                    规定如果管理层成员在其后3年中都在公司中任职服务，并且公司股价每年均提高10%以上
                  </div>
                </dd>
              </dl>
            </div>
            <div class="homeItem-more">探索所有课程 ></div>
          </div>
        </div>
        <div class="homeBg2">
          <div class="homeBg2-title">需要更多信息？</div>
          <div class="homeBg2-font">
            如果您考虑为下一个职业生涯做准备，我们可以提供帮助，立即索要更多信息
          </div>
          <div class="homeItem-more">探索所有课程 ></div>
        </div>
      </div>
    </main>
    <footer class="LoginFooter">
      <div class="FooterCenter">
        <ul>
          <li>首页华路</li>
          <li>•</li>
          <li>国际名校</li>
          <li>•</li>
          <li>资讯中心</li>
          <li>•</li>
          <li>华路校友会</li>
          <li>•</li>
          <li>华路商学院简介</li>
          <li>•</li>
          <li>我要约课</li>
        </ul>
        <div class="FooterRight">
          <p>COPYRIGHT © 2009-2020 华路数字文化 版权所有</p>
          <p>www.cufehl.com 粤ICP备14094585号-1</p>
        </div>
      </div>
    </footer>
    <div class="indexShow" v-if="indexShow">
      <div class="indexShowInfo">
        <div class="rNavHeader">
          华路文化在线课程
          <div @click="indexShow = false"></div>
        </div>
        <div class="rNavHeader-title">课程</div>
        <ul>
          <li>基础篇</li>
          <li>进阶篇</li>
          <li>金刚篇</li>
        </ul>
        <div class="rNavHeader-title">历年考卷</div>
        <ul>
          <li>2021年注册会计师考试卷</li>
          <li>2021年注册会计师考试卷</li>
          <li>2021年注册会计师考试卷</li>
        </ul>
        <div class="rNavHeader-title">更多信息</div>
        <ul>
          <li>关于我们</li>
          <li>我的课程</li>
          <li>我的账户</li>
        </ul>
        <div class="gduo">立即申请 ></div>
      </div>
    </div>
  </div>
</template>

<script>
import AskForHeader from "@/components/AskForHeader";
import Banner from "./components/Banner";

export default {
  name: "Home",
  data() {
    return {
      HomeFrom: {},
      indexShow: true,
    };
  },
  components: {
    AskForHeader,
    Banner,
  },
  created() {},
  methods: {
    HomeBut() {
      //提交索取
      console.log(this.HomeFrom);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container1 {
  flex: 1;
  padding: 0 0 42px 0;
  .HomeMian {
    width: 1200px;
    margin: 0 auto;
    color: #333333;
    .homeItem {
      margin-top: 80px;
      dl {
        margin: 0 -9px;
        dd {
          box-sizing: border-box;
          padding: 0 9px;
          float: left;
          width: 33.33333%;
          .itemFont {
            background: #ffffff;
            border: 1px solid #707070;

            border-radius: 6px;
            .itemImg {
              // height: 215px;
              img {
                width: 100%;
              }
            }
            .itemInfoFont {
              padding: 20px 30px;
              .title {
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 36px;
                color: #171717;
              }
              .content {
                margin-top: 10px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 24px;
                color: #181818;
              }
              .item {
                margin-top: 10px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 24px;
                color: #181818;

                position: relative;
                span {
                  position: absolute;
                  right: 0;
                  bottom: 0;
                }
              }
            }
          }
        }
      }
      &::after {
        display: block;
        content: "";
        clear: both;
      }
    }
    .homeItem-more {
      text-align: center;
      cursor: pointer;
      margin: 0 auto;
      margin-top: 40px;
      width: 134px;
      height: 35px;
      background: #ffffff;
      border: 1px solid #173d7c;

      border-radius: 3px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 35px;
      color: #173d7c;
    }
    .homeDl {
      margin-top: 80px;
      dl {
        margin: 0 -9px;
        dd {
          width: 33.33333%;
          float: left;
          box-sizing: border-box;
          padding: 0 9px;
          div {
            height: 80px;
            border: 1px solid #707070;

            border-radius: 6px;
            line-height: 80px;
            cursor: pointer;
            text-align: center;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #173d7c;

            &:hover {
              background: #000000;
              border: 1px solid #000000;

              border-radius: 6px;
              color: #ffffff;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
            }
          }
          &.on {
            div {
              background: #000000;
              border: 1px solid #000000;

              border-radius: 6px;
              color: #ffffff;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }
      }
      &::after {
        display: block;
        content: "";
        clear: both;
      }
    }
    .homeTitle {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 24px;
      color: #6b6c6d;

      text-align: center;
      margin-top: 60px;
      span {
        display: inline-block;
        padding: 0 55px;
        position: relative;
        &::after {
          display: block;
          content: "";
          height: 1px;
          position: absolute;
          left: 0;
          top: 13px;
          width: 40px;
          background: #f3c912;
        }
        &::before {
          display: block;
          content: "";
          height: 1px;
          position: absolute;
          right: 0;
          top: 13px;
          width: 40px;
          background: #f3c912;
        }
      }
    }
  }
}
.EduHome {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}
.LoginFooter {
  border-top: 1px solid #bfbfbf;
  .FooterCenter {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    ul {
      float: left;
      li {
        float: left;
        margin-right: 20px;
      }
    }
    .FooterRight {
      float: right;
      text-align: right;
      p {
        padding: 0;
        margin: 0;
        line-height: 24px;
      }
    }
  }
}
.homeBg {
  background: #f4f4f4;

  border-radius: 0px;
  padding-top: 50px;
  .itemcentent {
    margin: 0 auto;
    height: 321px;
    background: #ffffff;
    border: 1px solid #707070;

    border-radius: 6px;
    margin-top: 35px;
    .itemcententImg {
      float: left;
      width: 385px;
      margin: 25px;
      img {
        width: 100%;
      }
    }
    .itemcententFont {
      padding: 25px 25px 25px 450px;
      .itemcententTitle {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #191917;
        line-height: 30px;
        margin-top: 30px;
      }
      .itemcententInfo {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #173d7c;

        line-height: 45px;
      }
      .itemcententC {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #191917;

        line-height: 30px;
      }
      .homeItem-more1 {
        text-align: center;
        cursor: pointer;
        margin-top: 40px;
        width: 134px;
        height: 35px;
        background: #ffffff;
        border: 1px solid #173d7c;

        border-radius: 3px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 35px;
        color: #173d7c;
      }
    }
  }
}
.homeBg1 {
  background: #f4f4f4 url("~@/assets/index/img.png") no-repeat center;
  background-size: cover;

  border-radius: 0px;
  padding-top: 80px;
  padding-bottom: 0;
  .feature {
    height: 498px;
    background: #ffffff;

    border-radius: 0px;
    padding: 40px 20px;
    box-sizing: border-box;
    .mt0 {
      margin-top: 0;
    }
    .font {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 24px;
      color: #191917;

      margin: 0 auto;
      margin-top: 20px;
      text-align: center;
    }
    .featureDl {
      padding-top: 30px;
      text-align: center;
      dd {
        float: left;
        width: 33.33333%;
        box-sizing: border-box;
        padding: 20px;
      }
      .featureDl-icon {
        width: 48px;
        height: 48px;
        margin: 0 auto;
      }
      .featureD-title {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #191917;

        margin-top: 25px;
      }
      .featureD-font {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 30px;
        color: #191917;

        margin-top: 15px;
      }
      &::after {
        clear: both;
        content: "";
        display: block;
      }
    }
  }
}
.homeBg2 {
  margin-top: 120px;
  background: #173d7c url("~@/assets/index/bg-1.png") no-repeat center;
  background-size: cover;
  height: 250px;
  text-align: center;
  color: #ffffff;
  box-sizing: border-box;
  padding: 60px 0;
  .homeBg2-title {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 36px;
    color: #ffffff;
  }
  .homeBg2-font {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 32px;
    color: #ffffff;

    margin-top: 10px;
  }
  .homeItem-more {
    width: 134px;
    height: 35px;
    background: #ffffff;

    border-radius: 3px;
    margin: 0 auto;
    margin-top: 20px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #173d7c;

    line-height: 35px;
  }
}
.indexShow {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  .indexShowInfo {
    width: 400px;
    background-color: #173d7c;
    height: 100vh;
    overflow: auto;
    float: right;
    color: #ffffff;
    .rNavHeader {
      height: 68px;

      padding: 0 40px;
      position: relative;
      line-height: 68px;
      font-size: 28px;
      font-weight: 400;
      color: #ffffff;
      div {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 32px;
        height: 32px;
        cursor: pointer;
        background: url("~@/assets/index/gbi.png") no-repeat center;
        background-size: 32px;
      }
    }
    .rNavHeader-title {
      font-size: 24px;
      padding: 0 40px;
      line-height: 60px;
      padding-top: 15px;
      border-top: 1px solid rgba(255, 255, 255, 0.5);
    }
    ul {
      padding-bottom: 15px;
      li {
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
        padding: 0 40px 0 60px;
      }
    }
    .gduo {
      margin-left: 60px;
      height: 36px;
      background: #000000;
      border-radius: 2px;
      border: 1px solid #173d7c;
      color: #ffffff;
      padding: 0 20px;
      line-height: 36px;
      cursor: pointer;
      display: inline-block;
      font-size: 18px;
    }
  }
}
</style>
