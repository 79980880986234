<template>
  <div class="banner">
    <div class="bannerCenter">
      <div class="bannerCenterFont">
        <div class="bannerTitle">股份支付的确认和计量</div>
        <div class="bannerFont">
          通股权激励，实现团队合作，保持公司业绩增长。要想对“股份支付”充分理解，咱们首先要对公司实施股权激励的应用场景进行了解。
        </div>
        <a class="bannerHref" href="#">查看所有课程</a>
      </div>
      <div class="bannerCenterVideo">
        <video src="/i/movie.ogg" controls="controls"></video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "banner",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.banner {
  height: 170px;
  background: #173d7c;
  min-width: 1200px;
  height: 425px;
  .bannerCenter {
    margin: 0 auto;
    max-width: 1200px;
    .bannerCenterFont {
      float: left;
      color: #ffffff;
      width: 600px;
      .bannerTitle {
        font-size: 44px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        
        margin-top: 100px;
      }
      .bannerFont {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #ffffff;
        
        margin-top: 15px;
      }
      .bannerHref {
        width: 180px;
        height: 58px;
        background: #ffffff;
        
        border-radius: 4px;
        display: block;
        width: 144px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 58px;
        color: #173d7c;
        
        text-align: center;
        margin-top: 60px;
      }
    }
    .bannerCenterVideo {
      float: right;
      background: #ffffff;
      width: 578px;
      height: 326px;
      margin-top: 50px;
      video {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
